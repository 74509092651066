import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as kontakty1xfODHJIj9Meta } from "/app/pages/kontakty.vue?macro=true";
import { default as onaseK2nFSViTWMeta } from "/app/pages/onas.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kontakty",
    path: "/kontakty",
    component: () => import("/app/pages/kontakty.vue").then(m => m.default || m)
  },
  {
    name: "onas",
    path: "/onas",
    component: () => import("/app/pages/onas.vue").then(m => m.default || m)
  }
]