<template>
    <div class="notifications">
        <TransitionGroup name="list" tag="div">
            <div
                v-for="notification in $notifications"
                :key="notification.id"
                :class="['notification', notification.type]"
            >
                {{ notification.message }}
            </div>
        </TransitionGroup>
    </div>
</template>

<script setup>
import { useNuxtApp } from '#app'

const { $notifications } = useNuxtApp()
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
.notifications {
    position: fixed;
    top: 20px;
    transform: translateX(-50%);
    left: 50%;
    z-index: 9999;
}

.notification {
    margin-bottom: 10px;
    padding: 20px 30px;
    border-radius: 5px;
    color: #fff;
}

.notification.info {
    background-color: #2196f3;
}

.notification.success {
    background-color: #4caf50;
}

.notification.warning {
    background-color: #ff9800;
}

.notification.error {
    background-color: #f44336;
}
</style>
