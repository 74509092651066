export default defineNuxtPlugin((nuxtApp) => {
    const notifications = reactive<
        { id: number; message: string; type: string }[]
    >([])

    const addNotification = (message: string, type: string = 'info') => {
        const id = Date.now()
        notifications.push({ id, message, type })

        // Automatically remove the notification after 5 seconds
        setTimeout(() => {
            removeNotification(id)
        }, 5000)
    }

    const removeNotification = (id: number) => {
        const index = notifications.findIndex((n) => n.id === id)
        if (index !== -1) {
            notifications.splice(index, 1)
        }
    }

    nuxtApp.provide('notify', addNotification)
    nuxtApp.provide('notifications', notifications)
})
